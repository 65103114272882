import React from "react"
import LayoutPage from "../../components/layout-page"
import SEO from "../../components/seo"
import ThankYou from "../../components/thank-you"
import ImageMeta from "../../components/ImageMeta"

const PluginThankYou = () => (
  <LayoutPage>
    <SEO title={"Thank you for your interest"} meta={ImageMeta}/>
    <ThankYou title={"Thank you for your interest in sharing your gear"}>
      We have added your email to the list of people interested in offering their gear on mix:analog. We are looking forward to reviewing your proposal and getting back to you as
      soon as possible. Have a nice day!
    </ThankYou>
  </LayoutPage>
)

export default PluginThankYou
